import {UserHasRight} from '../../services/security/user-has-right';

export interface ItemNavigation {

  key: NAV_KEY;
  icon: string;
  label: string;
  breadcrumb: string[];
  routerLink?: string[];
  userHasRight: UserHasRight;
  children?: ItemNavigation[];

  dialog?: string;

  confirmation?: string;

}
export enum NAV_TYPE {
  ICON= 'icon',
  LABEL= 'label'
}
export enum NAV_KEY {
  MON_BUREAU= 'Mon bureau',
  REFERENTIEL= 'Référentiel',
  EQUILIBRE_ALIMENTAIRE= 'Équilibre alimentaire',
  FAMILLES_GEMRCN= 'Familles diététiques',
  REGIMES_ALIMENTAIRES= 'Régimes alimentaires',
  GESTION_DES_UTILISATEURS= 'Gestion des utilisateurs',
  SITES= 'Sites',
  ENVIRONNEMENTS= 'Environnements',
  ENVIRONNEMENTS_PLC= 'Env. points de livraison',
  CONFIGURATION_PORTAIL= 'Configuration portail',
  CONFIGURATION_PORTAIL_PLC= 'Config. par points de livraison',
  PROFILS= 'Profils',
  UTILISATEURS= 'Utilisateurs',
  CONNEXIONS_UTILISATEURS= 'Connexions utilisateurs',
  REFERENTIEL_PRODUCTION= 'referentiel_production',
  EQUIPES= 'Équipes',
  SECTEURS= 'Secteurs',
  ATELIERS= 'Ateliers',
  ZONES_DE_STOCKAGE= 'Zones de stockage',
  RECEPTION= 'Réception',
  LITIGES= 'Litiges',
  MOTIFS_DE_NON_CONFORMITE= 'Motifs de non conformité',
  REFERENTIEL_DE_BASE= 'Réferentiel de base',
  ALLERGENES= 'Allergènes',
  APPELLATIONS = 'Appellations',
  PRESTATIONS = 'Prestations',
  COMPOSANTES_REPAS = 'Composantes repas',
  DECLINAISONS = 'Déclinaisons',
  FAMILLES_PRODUITS = 'Familles de produits',
  REPAS = 'Repas',
  ORIGINES ='Origines',
  TAXES = 'Taxes',
  TYPES_DE_PRODUITS = 'Types de produits',
  TACHES = 'Tâches',

  TOURNEES = 'Tournées',
  UNITES_DE_MESURES = 'Unités de mesures',
  CONDITIONNEMENTS = 'Conditionnements',
  VARIANTES = 'Variantes',
  RESTES = 'Restes',
  CONDITIONNEMENTS_VARIANTES = 'Conditionnements - Variantes',

  CLEAR_CONDITIONNEMENT = 'Purge complète',
  WIZARD_CONDITIONNEMENT= 'Assistant de configuration',
  MODELES_PLATS= 'Modèles de plats',
  MODELES_PLC='Modèles points de livraison',
  PARAMETRES= 'Paramètres',
  GROUPE_ACHAT= 'Groupes achats',
  GROUPE_ACHAT_LIST= 'Liste groupes achats',
  FOURNISSEURS= 'Fournisseurs',
  LISTE_DES_FOURNISSEURS= 'Liste des fournisseurs',
  MISE_A_JOUR_DES_ARTICLES= 'Mise à jour des articles',
  MARCHES= 'Marchés',
  UNITES_DE_PRODUCTION= 'Unités de production',
  PRODUITS= 'Produits',
  MENUS= 'Menus',
  MODELES_DE_MENUS= 'Modèles de menus',
  OFFRES_ALIMENTAIRES= 'Offres alimentaires',
  PLANNING_DES_MENUS= 'Planning des menus',
  CLIENTS= 'Commandes Clients',
  PLANNING_DES_EFFECTIFS= 'Planning des effectifs',
  PORTAIL_SAISI_EFFECTIF= 'Portail de saisie des effectifs',
  CLIENTS_ET_POINTS_DE_LIVRAISONS= 'Clients et points de livraison',
  BESOINS= 'Besoins',
  COMMANDES_FOURNISSEURS= 'Commandes fournisseurs',
  COMMANDES_TABLEAU_DE_BORD= 'Commandes_tableau_de_bord',
  PROPOSITIONS_DE_COMMANDES= 'Propositions de commandes',
  COMMANDES_REFUSEES= 'Commandes refusées',
  COMMANDES_A_VALIDER= 'Commandes à valider',
  COMMANDES_A_RECEPTIONNER= 'Commandes à réceptionner',
  COMMANDES_RECEPTIONNEES= 'Commandes réceptionnées',
  COMMANDES_FACTUREES= 'Commandes facturées',
  STOCKS= 'Stocks',
  MATIERES_PREMIERES= 'Matières premières',
  MOUVEMENTS= 'Mouvements',
  INVENTAIRE= 'Inventaire',
  PRODUCTION= 'Production',

  ORGANISATIONS_TOURNEES = 'Organisations de tournées',
  STOCKS_MOUVEMENTS_STATUTS='Motifs de mouvement',
  PMS='Plan de maîtrise sanitaire',
  UNITES_PMS='Unités',
  SEUILS_PMS='Seuils',
  EQUIPEMENTS_PMS='Equipements',
  ZONES_PMS='Zones à nettoyer',
  EQUIPEMENTS_A_NETTOYER_PMS='Equipements à nettoyer',
  SIGNATURES_UTILISATEUR_PMS='Signatures utilisateur',
  TYPES_UTILISATEUR_PMS='Types d\'utilisateur',
  TYPES_TACHE_PMS='Types de tâche',
  TYPES_EQUIPEMENT_PMS='Types d\'équipement',
  TACHES_PMS='Tâches',
  CHANGELOGS='Versions',
  GESTION_ADMINISTRATION='Administration',
  FACTURES='Facturation',
  FACTURES_FOURNISSEURS='Factures fournisseurs',
  FACTURES_PLC='Factures Points de livraison Client',
  AVOIRS='Avoirs fournisseurs',

}
