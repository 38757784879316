import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from "../../../../core/utils/utils.service";
import {PointDeLivraisonService} from "../../../../core/services/entities/point-de-livraison.service";
import {STATUTS_MENUS} from 'app/core/constants';
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {Subscription} from "rxjs";
import RepasEffectifsCommandesClientDTO from "../../../../core/dtos/planification/repas-effectifs-commandes-client-dto";
import DatesDetailsFromMenuDTO from "../../../../core/dtos/planification/dates-details-from-menu-dto";
import PrestationCommandeClientDTO from "../../../../core/dtos/planification/prestation-commande-client-dto";
import RowEffectifsCommandesClientDTO from "../../../../core/dtos/planification/row-effectifs-commandes-client-dto";
import {TypeEffectifEnum} from "../../../../core/enums/type-effectif-enum";
import {CommandesClientService} from "../../../../core/services/entities/commandes-client.service";
import RepasCommandeClientDTO from "../../../../core/dtos/planification/repas-commande-client-dto";
import PrestationsEffectifsCommandesClientsDTO
  from "../../../../core/dtos/planification/prestations-effectifs-commandes-clients-dto";

@Component({
  selector: 'yo-prestations-repas',
  templateUrl: './prestations-repas.component.html',
  styleUrls: ['./prestations-repas.component.scss']
})
export class PrestationsRepasComponent implements OnInit, OnDestroy {

  subTypeEffectif: Subscription;
  subChangeEffectifsCommandesClients: Subscription;
  subSelectedCell: Subscription;

  colWidth: 80;

  STATUTS_MENUS = STATUTS_MENUS;
  TypeEffectifEnum = TypeEffectifEnum;

  selectedCell: RowEffectifsCommandesClientDTO;
  selectedRepas: RepasCommandeClientDTO;

  @Input('repas') repas: RepasEffectifsCommandesClientDTO[];
  @Input('prestation') prestation: PrestationCommandeClientDTO;
  @Input("datesDetailsFromMenu") datesDetailsFromMenu: DatesDetailsFromMenuDTO;
  @Input("cols") cols: Date[];
  @Input("typeEffectif") typeEffectif: TypeEffectifEnum;

  loadingVisible: boolean = false;

  timer: any;

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private plcSvc: PointDeLivraisonService,
              private commandesClientSvc: CommandesClientService) {
  }

  ngOnInit(): void {
    this.typeEffectifSubscription();
    this.changeEffectifsCommandesClientsSubscription();
    this.selectedCellSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subTypeEffectif);
    this.utils.unsubscribe(this.subChangeEffectifsCommandesClients);
    this.utils.unsubscribe(this.subSelectedCell);
  }

  keyDown = event => event.target.value = event.target.value.replace(/[^0-9]/g, "");

  /**
   * Subscription qui permet de récupérer le type d'effectif sélectionné
   */
  typeEffectifSubscription = () => {
    this.subTypeEffectif = this.commandesClientSvc.selectedTypeEffectif$.subscribe(response => {
      this.typeEffectif = response;
      if(this.selectedCell) this.showRepas(this.selectedCell);
    });
  };

  /**
   * Subscription qui permet de récupérer la cellule sélectionnée
   */
  selectedCellSubscription = () => {
    this.subSelectedCell = this.commandesClientSvc.selectedCell$
      .subscribe(response => this.selectedCell = response);
  };

  /**
   * Subscription qui permet de détecter une modification du planning des effectifs
   * Si c'est le cas, et qu'une cellule était sélectionnée, on la sélectionne de nouveau
   */
  changeEffectifsCommandesClientsSubscription = () => {
    this.subChangeEffectifsCommandesClients =
      this.commandesClientSvc.changeEffectifsCommandesClients$.subscribe(() => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          const elem = document.getElementById(String(this.selectedCell?.cmcrPlcDate.id));
          elem.focus();
        }, 100);
      });
  };

  /**
   * Méthode qui permet d'afficher les détails de la cellule sélectionnée
   */
  showRepas = (cell: RowEffectifsCommandesClientDTO) => {
    this.selectedCell = cell;

    this.selectedRepas = this.repas.find(r => r.rows.find(row => row.cmcrPlcDate.id === cell.cmcrPlcDate.id))?.repas;
    this.commandesClientSvc.announceSelectedCell(this.selectedCell);
    this.commandesClientSvc.announceSelectedRepas(this.selectedRepas);
    this.commandesClientSvc.announceSelectedPrestation(this.prestation);

    if (this.selectedCell) {
      this.commandesClientSvc
        .announceDetailsEffectifCommandeClient(this.selectedCell.details.filter(detail => detail.typeEffectif === this.typeEffectif));
    }
  }

  /**
   * Méthode qui permet de permettre, ou non, à un utilisateur de modifier un effectif selon la cellule
   */
  isEffectifInputDisabled = (cell: RowEffectifsCommandesClientDTO): boolean => {
    if (this.showStatutMode(cell) != STATUTS_MENUS.SAISIE_EFFECTIFS) return true;
    else return !this.auth2Svc.isSiteLocal(cell.cmcrPlcDate.cmSiteId);
  };

  /**
   * Méthode qui permet de modifier l'effectif de la cellule sélectionnée
   */
  changeEffectif = ($event: number, cell: RowEffectifsCommandesClientDTO) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.selectedCell = cell;
      if (this.typeEffectif === TypeEffectifEnum.PREVISIONNEL) cell.effectifPrevisionnel = $event;
      if (this.typeEffectif === TypeEffectifEnum.FABRICATION) cell.effectifFabrication = $event;
      if (this.typeEffectif === TypeEffectifEnum.FACTURATION) cell.effectifFacturation = $event;

      this.commandesClientSvc.announceRowUpdated(cell);
    }, 25);
  };

  /**
   * Méthode qui permet d'afficher le statut du menu
   */
  showStatutMode = (row: RowEffectifsCommandesClientDTO) => {
    if (this.datesDetailsFromMenu.datesCreationMenus.find(d => d === row.date)) return STATUTS_MENUS.CREATION_MENUS;
    else if (this.datesDetailsFromMenu.datesSaisieEffectifs.find(d => d === row.date)) return STATUTS_MENUS.SAISIE_EFFECTIFS;
  }

  /**
   * Méthode qui permet d'afficher le régime associé à la cellule sélectionnée
   */
  showRegime = (item: RepasEffectifsCommandesClientDTO) => item.rows[0].regime.libelle;

  /**
   * Méthode qui permet de récupérer l'effectif lié à la colonne, donc ici lié à la date
   */
  getEffectif = (col: Date, rows: RowEffectifsCommandesClientDTO[]) => rows.find(row => new Date(row.date).getDate() === col.getDate());

}
