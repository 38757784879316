import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {
  HELP_FOLDERS,
  MSG_KEY,
  MSG_SEVERITY,
  STATUT_SORTIE_POUR_PRODUCTION,
  UI_COLORS,
  VUE_SORTIES_POUR_PRODUCTION
} from '../../../core/constants';
import {MenuItem} from 'primeng/api';
import {SortiePourProductionService} from '../../../core/services/entities/sortie-pour-production.service';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {RoutemapService} from '../../../core/services/routemap.service';
import {DATAGRID_ROW_TYPES, DevextremeService} from '../../../core/services/technique/devextreme.service';
import {DxDataGridComponent} from 'devextreme-angular';
import {SortiePourProductionDTO} from '../../../core/dtos/sortie-pour-production-dto';
import {ToastService} from "../../../core/services/technique/toast.service";
import {PlanProductionDTO} from "../../../core/dtos/plan-production-dto";
import {ProductionService} from "../../../core/services/gestion-production/production.service";
import {saveAs as fs_saveAs} from "file-saver";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {confirm} from "devextreme/ui/dialog";


@Component({
  selector: 'yo-sorties-production',
  templateUrl: './sorties-production.component.html',
  styleUrls: ['./sorties-production.component.scss']
})
export class SortiesProductionComponent implements OnInit, OnDestroy {

  subRoute: Subscription;
  VUE_SORTIES_POUR_PRODUCTION = VUE_SORTIES_POUR_PRODUCTION;

  valuePlc: boolean = false;
  valueMeal: boolean = false;
  valueConsumptionDate: boolean = false;

  displayOptionsDisplayDialog: boolean = false;

  popupPosition = {of: window, at: 'top', my: 'top', offset: {y: 10}};

  @ViewChild('grid') grid: DxDataGridComponent;

  chooseView: any[] = [
    {
      label: 'DENRÉES',
      value: VUE_SORTIES_POUR_PRODUCTION.DENREE
    },
    {
      label: 'STOCKS',
      value: VUE_SORTIES_POUR_PRODUCTION.STOCK
    },
  ];
  selectedVue: VUE_SORTIES_POUR_PRODUCTION = VUE_SORTIES_POUR_PRODUCTION.DENREE;

  pathFile: string =  HELP_FOLDERS.PLANS_PRODUCTION + '/sorties-production';

  planProduction: PlanProductionDTO;

  columnQteRealUSValue = 'quantiteReelleUniteStockage.value';

  selectedDetails: SortiePourProductionDTO[] = [];

  displayDialogMassiveTreatment: boolean = false;

  massiveQteReelleUs: number;

  aroundQteReelleUs: boolean = false;

  constructor(public utils: UtilsService,
              private i8nSvc: InternationalizationService,
              private prodSvc: ProductionService,
              public sppSvc: SortiePourProductionService,
              public gfs: GenericFormService,
              public dxSvc: DevextremeService,
              public routeMapSvc: RoutemapService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initSortiesSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
  }

  initSortiesSubscription = () => {
      const pathnameSplitted = window.location.pathname.split("/");
      const idPlanProduction = parseInt(pathnameSplitted[pathnameSplitted.length - 1].replace(")",""));
    const filters: any = { groupByPlc: this.valuePlc, groupByMeal: this.valueMeal, groupByConsumptionDate: this.valueConsumptionDate, viewSelected: this.selectedVue };
    this.fetchSorties(filters, idPlanProduction);
  };

  closeOptionsDisplayDialog = (): void => { this.displayOptionsDisplayDialog = false; }

  openOptionsDisplayDialog = (): void => { this.displayOptionsDisplayDialog = true; }

  initClassAccordingStatus = (currentStatus: string): string => {
    const root = 'far fa-square fa-stack-2x ';
    switch(currentStatus) {
      case 'PROPOSITION_DE_SORTIE':
        return `${root}color-proposition-sortie`;
        break;
      case 'SORTIE_VALIDEE':
        return `${root}color-sortie-validee`;
        break;
      case 'RUPTURE_PARTIELLE':
        return `${root}color-rupture-partielle`;
        break;
      default:
        return `${root}color-sortie-rupture`;
        break;
    }
  }

  statutRupturePartielleCss = (currentStatus: string) => {
    if (currentStatus === 'RUPTURE_PARTIELLE') return `color-rupture-partielle`;
  }

  changeView = ($event: any) => {
    this.selectedVue = $event.addedItems[0].value;
    this.selectedDetails = [];
    const filters: any = { groupByPlc: this.valuePlc, groupByMeal: this.valueMeal, groupByConsumptionDate: this.valueConsumptionDate, viewSelected: this.selectedVue };
    this.fetchSorties(filters);
  };

  applyDisplayOptions = () => {
    const filters: any = { groupByPlc: this.valuePlc, groupByMeal: this.valueMeal, groupByConsumptionDate: this.valueConsumptionDate, viewSelected: this.selectedVue };
    this.fetchSorties(filters);
    this.closeOptionsDisplayDialog();
  }

  private fetchSorties = (filters: any, idPlanProduction?: number): void => {
    this.prodSvc.findSorties(!this.planProduction ? idPlanProduction : this.planProduction.id, filters)
      .subscribe((response) => {
        this.planProduction = response.one;

        if (this.planProduction.typeVueSortieProduction) {
          switch (this.planProduction.typeVueSortieProduction) {
            case 'DENREE':
              this.selectedVue = VUE_SORTIES_POUR_PRODUCTION.DENREE;
              break;
            default:
              this.selectedVue = VUE_SORTIES_POUR_PRODUCTION.STOCK;
              break;
          }
        } else {
          this.selectedVue = VUE_SORTIES_POUR_PRODUCTION.DENREE;
        }
      });
  };

  validerSorties = async () => {
    let sortiesAValider: SortiePourProductionDTO[] = Object.assign([], this.selectedDetails);
    if (!sortiesAValider || !sortiesAValider.length) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Veuillez sélectionnez des sorties pour production avant de valider les sorties`);
    } else {
      sortiesAValider = sortiesAValider.filter(s => s.status === STATUT_SORTIE_POUR_PRODUCTION.PROPOSITION_DE_SORTIE || s.status === STATUT_SORTIE_POUR_PRODUCTION.RUPTURE_PARTIELLE);
      const result = confirm(`Êtes vous sûr de vouloir valider les sorties pour production ?`, 'Confirmation');
      const isConfirmed: boolean = await result;
      if (isConfirmed) {
        const currentView: string = this.selectedVue === VUE_SORTIES_POUR_PRODUCTION.DENREE ? 'DENREE' : 'STOCK';
        this.sppSvc.validerSorties(this.planProduction.id, sortiesAValider, currentView).subscribe(() => {
          const filters: any = { groupByPlc: this.valuePlc, groupByMeal: this.valueMeal, groupByConsumptionDate: this.valueConsumptionDate, viewSelected: this.selectedVue };
          this.fetchSorties(filters);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les sorties ont été validées avec succès`);
        });
      }
    }
  };

  generatePlanProduction = () => {
    this.routeMapSvc.goToSecondaryRoute(['steps', 'plan-production', this.planProduction.id]);
  }

  handleLotArticleDisplay = (lotArticle: string) => {
    if (!lotArticle) return '';
    return lotArticle === 'NOT_LOT_AVAILABLE' ? this.i8nSvc.getLabelFromCode(lotArticle, []) : lotArticle;
  }

  isDisabledPrintBtn = () => this.planProduction?.sorties?.length === 0;

  isColumnPlcVisible = (): boolean => this.valuePlc && this.planProduction?.sorties[0].pointDeLivraison !== undefined && this.planProduction?.sorties[0].pointDeLivraison !== null;

  isColumnMealVisible = (): boolean => this.valueMeal && this.planProduction?.sorties[0].repas !== undefined && this.planProduction?.sorties[0].repas !== null;

  isColumnDateConsoVisible = (): boolean => this.valueConsumptionDate && this.planProduction?.sorties[0].dateConsommation !== undefined && this.planProduction?.sorties[0].dateConsommation !== null;

  isEditable = (): boolean => this.auth2Svc.isSiteLocal(this.planProduction?.site?.id);

  onCellPrepared = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.HEADER && this.isEditable()) {
      if (event.column.allowEditing === true) {
        event.cellElement.style.backgroundColor = UI_COLORS.EDITABLE;
      }
    }
    // La coloration de la cellule ne se fera qu'en granularité maximale
    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.column.dataField === this.columnQteRealUSValue && this.valueMeal && this.valuePlc && this.valueConsumptionDate && event.data?.modificationManuallyQuantiteReelleUniteStockage) event.cellElement.style.backgroundColor = UI_COLORS.MODIFICATION_MANUELLE;
  };

  onEditorPreparing = (event: any) => {
    if (event.parentType === 'dataRow' && event.editorName !== 'dxCheckBox') {
      const self = this;
      const currentRow = event.row.data;
      event.editorOptions.onValueChanged = function(args: any) {
        const dataField: string = event.dataField;
        if (args.value !== args.previousValue) {
          if (dataField === self.columnQteRealUSValue && event.row.data.status === STATUT_SORTIE_POUR_PRODUCTION.PROPOSITION_DE_SORTIE && (event.row.data.type === VUE_SORTIES_POUR_PRODUCTION.DENREE || (event.row.data.type === VUE_SORTIES_POUR_PRODUCTION.STOCK && event.row.data.stock && event.row.data.stock.lotArticle !== "NOT_LOT_AVAILABLE") ) ) {
            if (event.row.data.type === VUE_SORTIES_POUR_PRODUCTION.STOCK && args.value > currentRow.stock.quantite) {
              self.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, self.i8nSvc.getLabelFromCode("CANNOT_UPDATE_REAL_QTY_US_GT_STOCK", []));
            } else {
              currentRow['quantiteReelleUniteStockage']['value'] = args.value;
              const sortiesUpdates: any[] = [currentRow];
              self.prodSvc.updateRealQuantitiesUs(self.planProduction.id, self.selectedVue, sortiesUpdates, false)
                .subscribe(() => {
                  const filters: any = { groupByPlc: self.valuePlc, groupByMeal: self.valueMeal, groupByConsumptionDate: self.valueConsumptionDate, viewSelected: self.selectedVue };
                  self.fetchSorties(filters);
                });
            }
          } else if (dataField === self.columnQteRealUSValue && event.row.data.status === STATUT_SORTIE_POUR_PRODUCTION.PROPOSITION_DE_SORTIE && event.row.data.type === VUE_SORTIES_POUR_PRODUCTION.STOCK && event.row.data.stock && event.row.data.stock.lotArticle === "NOT_LOT_AVAILABLE") {
            self.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, self.i8nSvc.getLabelFromCode("CANNOT_UPDATE_REAL_QTY_US_ROW_WITHOUT_STOCK", []));
          } else if (dataField === self.columnQteRealUSValue && event.row.data.status !== STATUT_SORTIE_POUR_PRODUCTION.PROPOSITION_DE_SORTIE) {
            self.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, self.i8nSvc.getLabelFromCode("CANNOT_UPDATE_REAL_QTY_US_ROW_VALID_SOLD_OUT", []));
          }
        }
      };
    }
  }

  onEditingStart = (event: any) => {
    console.log(event.data, event.column.dataField);
  };

  onSelectionChanged = (event: any): void => this.selectedDetails = event.selectedRowsData;

  openMassiveTreatmentDialog = () => this.displayDialogMassiveTreatment = true

  closeDialogMassiveTreatment = () => {
    this.displayDialogMassiveTreatment = false;
    this.massiveQteReelleUs = undefined;
    this.aroundQteReelleUs = false;
  }

  applyMassiveTreatment = () => {
    let sortiesUpdates: SortiePourProductionDTO[] = Object.assign([], this.selectedDetails);
    if (this.massiveQteReelleUs !== null && this.massiveQteReelleUs !== undefined) {
      sortiesUpdates = sortiesUpdates.map((currentRow: SortiePourProductionDTO) => {
        currentRow['quantiteReelleUniteStockage']['value'] = this.massiveQteReelleUs;
        return currentRow;
      });
    }
    this.prodSvc.updateRealQuantitiesUs(this.planProduction.id, this.selectedVue, sortiesUpdates, this.aroundQteReelleUs)
      .subscribe(() => {
        const filters: any = { groupByPlc: this.valuePlc, groupByMeal: this.valueMeal, groupByConsumptionDate: this.valueConsumptionDate, viewSelected: this.selectedVue };
        this.fetchSorties(filters);
      });
    this.closeDialogMassiveTreatment();
  }

  openPrintSortiesProductionSettings = () => {
    this.prodSvc.announceOpenPrintSortiesProductionSettings(this.planProduction, this.selectedVue);
  }
}
