import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {PointDeLivraisonService} from '../../../core/services/entities/point-de-livraison.service';
import {MenuItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {MenucompositionPlcService} from '../../../core/services/entities/menucomposition-plc.service';
import {Menu} from 'primeng/menu';
import {
  DialogRecherchePlatsSupplier,
  MenusPlanning2Service
} from '../../../core/services/gestionmenus/menus-planning2.service';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {SEARCH_PLATS_TYPE_DIALOG, STATUTS_MENUS, USER_PREFERENCE} from '../../../core/constants';
import {PreferencesUtilisateurService} from '../../../core/services/preferences-utilisateur.service';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {CommandesClientService} from "../../../core/services/entities/commandes-client.service";
import DetailEffectifCommandeClientDTO from "../../../core/dtos/planification/detail-effectif-commande-client-dto";
import RowEffectifsCommandesClientDTO from "../../../core/dtos/planification/row-effectifs-commandes-client-dto";
import {ContratMenuConviveDTO} from "../../../core/dtos/contratmenuconvive-dto";
import RepasCommandeClientDTO from "../../../core/dtos/planification/repas-commande-client-dto";
import {RepasDTO} from "../../../core/dtos/repas-dto";
import PrestationCommandeClientDTO from "../../../core/dtos/planification/prestation-commande-client-dto";
import {TypeEffectifEnum} from "../../../core/enums/type-effectif-enum";

@Component({
  selector: 'yo-repas',
  templateUrl: './repas.component.html',
  styleUrls: ['./repas.component.scss']
})
export class RepasComponent implements OnInit, OnDestroy {

  subDetailsEffectifCommandeClient: Subscription;
  subTypeEffectif: Subscription;
  subAddPlat: Subscription;
  subCellSelected: Subscription;
  subRepasSelected: Subscription;
  subPrestationSelected: Subscription;

  detailsEffectifCommandeClient: DetailEffectifCommandeClientDTO[];
  selectedCell: RowEffectifsCommandesClientDTO;
  selectedRow: DetailEffectifCommandeClientDTO;
  selectedRepas: RepasCommandeClientDTO;
  selectedPrestation: PrestationCommandeClientDTO;
  selectedDetail: DetailEffectifCommandeClientDTO;

  STATUTS_MENUS = STATUTS_MENUS;
  TypeEffectifEnum = TypeEffectifEnum;
  typeEffectif: TypeEffectifEnum = TypeEffectifEnum.PREVISIONNEL;
  actions: MenuItem[] = [];

  timer: any;

  cellsUpdated: DetailEffectifCommandeClientDTO[] = [];
  isUpdated: boolean = false;
  isFirstClick: boolean = true;

  constructor(public utils: UtilsService,
              private plcSvc: PointDeLivraisonService,
              private prefUsrSvc: PreferencesUtilisateurService,
              private mp2Svc: MenusPlanning2Service,
              private mcPlcSvc: MenucompositionPlcService,
              private pdSvc: ProduitDeclinaisonService,
              private auth2Svc: Auth2Service,
              private commandesClientSvc: CommandesClientService) {
  }

  ngOnInit() {
    this.typeEffectifSubscription();
    this.detailsEffectifCommandeClientSubscription();
    this.cellSelectedSubscription();
    this.repasSelectedSubscription();
    this.prestationSelectedSubscription();
    this.addPlatSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDetailsEffectifCommandeClient);
    this.utils.unsubscribe(this.subTypeEffectif);
    this.utils.unsubscribe(this.subAddPlat);
    this.utils.unsubscribe(this.subCellSelected);
    this.utils.unsubscribe(this.subRepasSelected);
    this.utils.unsubscribe(this.subPrestationSelected);
  }

  keyDown = event => event.target.value = event.target.value.replace(/[^0-9]/g, "");

  /**
   * Subscription qui permet de récupérer le type d'effectif sélectionné
   */
  typeEffectifSubscription = () => {
    this.subTypeEffectif = this.commandesClientSvc.selectedTypeEffectif$.subscribe(response => this.typeEffectif = response);
  };

  /**
   * Subscription qui permet de récupérer la cellule sélectionnée
   */
  cellSelectedSubscription = () => {
    this.subCellSelected = this.commandesClientSvc.selectedCell$
      .subscribe(response => this.selectedCell = response);
  };

  /**
   * Subscription qui permet de récupérer le repas sélectionné
   */
  repasSelectedSubscription = () => {
    this.subRepasSelected = this.commandesClientSvc.selectedRepas$
      .subscribe(response => this.selectedRepas = response);
  };

  /**
   * Subscription qui permet de récupérer la prestation sélectionnée
   */
  prestationSelectedSubscription = () => {
    this.subPrestationSelected = this.commandesClientSvc.selectedPrestation$
      .subscribe(response => this.selectedPrestation = response);
  };

  /**
   * Subscription qui permet de récupérer les détails liés à la cellule sélectionnée
   */
  detailsEffectifCommandeClientSubscription = () => {
    this.subDetailsEffectifCommandeClient = this.commandesClientSvc.detailsEffectifCommandeClient$
      .subscribe(response => {
        console.log(response)
        this.detailsEffectifCommandeClient = response;
        if (!this.utils.isCollectionNullOrEmpty(this.detailsEffectifCommandeClient))
          this.detailsEffectifCommandeClient.sort((a, b) => a.composanteRepas.ordre - b.composanteRepas.ordre);
      });
  };

  /**
   * Subscription qui permet de récupérer le plat sélectionné et de modifier les détails par la suite
   */
  addPlatSubscription = () => {
    this.subAddPlat = this.mcPlcSvc.addProduitDeclinaisonFromSearch$
      .subscribe(response => {
        this.selectedDetail.plat = response.produitsDeclinaisonsInCart[0];
        this.selectedDetail.actif = true;
        this.selectedDetail.modificationManuelle = true;
        this.isUpdated = false;
        this.changeProduitDeclinaison(null, true);
      });
  };

  /**
   * Méthode utilisée pour modifier l'effectif d'un détail
   */
  changeEffectif = ($event: any, cell: DetailEffectifCommandeClientDTO) => {
    let detail: DetailEffectifCommandeClientDTO = Object.assign({}, cell);
    detail.effectifPrevu = $event.target.value;
    detail.arrondiEffectifPrevu = false;
    detail.effectifPrevuUpdated = true;

    this.commandesClientSvc.announceDetailUpdated(detail);

    cell.effectifPrevu = $event.target.value;
    this.updateTauxDePrise(cell);

    this.isUpdated = true;
  };

  updateEffectif = (cell: DetailEffectifCommandeClientDTO) => {
    switch (this.typeEffectif) {
      case TypeEffectifEnum.PREVISIONNEL:
        if (this.selectedCell.effectifPrevisionnel) cell.effectifPrevu = Math.round((cell.tauxPrise * this.selectedCell.effectifPrevisionnel) / 100);
        cell.arrondiEffectifPrevu = false;
        break;
      case TypeEffectifEnum.FABRICATION:
        if (this.selectedCell.effectifFabrication) cell.effectifPrevu = Math.round((cell.tauxPrise * this.selectedCell.effectifFabrication) / 100);
        cell.arrondiEffectifPrevu = false;
        break;
      case TypeEffectifEnum.FACTURATION:
        if (this.selectedCell.effectifFacturation) cell.effectifPrevu = Math.round((cell.tauxPrise * this.selectedCell.effectifFacturation) / 100);
        cell.arrondiEffectifPrevu = false;
        break;
    }
  }

  /**
   * Méthode utilisée pour modifier le taux de prise d'un détail
   */
  changeTauxDePrise = ($event: any, cell: DetailEffectifCommandeClientDTO) => {
    let detail: DetailEffectifCommandeClientDTO = Object.assign({}, cell);
    detail.tauxPrise = $event.target.value;
    detail.arrondiEffectifPrevu = false;
    detail.effectifPrevuUpdated = false;

    this.commandesClientSvc.announceDetailUpdated(detail);

    cell.tauxPrise = $event.target.value;
    this.updateEffectif(cell);

    this.isUpdated = true;
  };

  updateTauxDePrise = (cell: DetailEffectifCommandeClientDTO) => {
    switch (this.typeEffectif) {
      case TypeEffectifEnum.PREVISIONNEL:
        if (this.selectedCell.effectifPrevisionnel) cell.tauxPrise = Math.round((cell.effectifPrevu / this.selectedCell.effectifPrevisionnel) * 100);
        cell.arrondiEffectifPrevu = false;
        break;
      case TypeEffectifEnum.FABRICATION:
        if (this.selectedCell.effectifFabrication) cell.tauxPrise = Math.round((cell.effectifPrevu / this.selectedCell.effectifFabrication) * 100);
        cell.arrondiEffectifPrevu = false;
        break;
      case TypeEffectifEnum.FACTURATION:
        if (this.selectedCell.effectifFacturation) cell.tauxPrise = Math.round((cell.effectifPrevu / this.selectedCell.effectifFacturation) * 100);
        cell.arrondiEffectifPrevu = false;
        break;
    }
  }

  /**
   * Méthode utilisée pour modifier le plat d'un détail
   */
  changeProduitDeclinaison = (platRestored?: boolean, platUpdated?: boolean) => {
    this.commandesClientSvc.announceSaveEffectifsCommandesClientsFromDetail(platRestored, platUpdated, [this.selectedDetail]);
    this.pdSvc.displayDialogRecherchePlats = false;
  }

  /**
   * Méthode qui permet d'initialiser les données nécessaires pour avoir les plats correspondant
   * aux données actuelles de l'utilisateur
   */
  inputsForSearch = (rowData: DetailEffectifCommandeClientDTO): DialogRecherchePlatsSupplier => {
    const presta: ContratMenuConviveDTO = new ContratMenuConviveDTO();
    presta.id = this.selectedPrestation.id;
    presta.libelle = this.selectedPrestation.libelle;
    presta.code = this.selectedPrestation.code;
    presta.actif = this.selectedPrestation.actif;

    const repas: RepasDTO = new RepasDTO();
    repas.id = this.selectedRepas.id;
    repas.libelle = this.selectedRepas.libelle;
    repas.actif = this.selectedRepas.actif;
    repas.ordre = this.selectedRepas.ordre;
    repas.code = this.selectedRepas.code;

    const inputsSearch = new DialogRecherchePlatsSupplier();
    inputsSearch.cmcd = rowData.cmcd;
    inputsSearch.dateMenu = this.selectedCell.date;
    inputsSearch.decoupageRepas = rowData.composanteRepas;
    inputsSearch.repas = repas;
    inputsSearch.choixMax = rowData.cmcd.nombreChoixMaximum;
    inputsSearch.maxItemsPanier = 1;
    inputsSearch.cmcContrainteAlim = rowData.cmcca;
    inputsSearch.regimeAlimentaire = this.selectedCell.regime;
    inputsSearch.prestation = presta;
    // 1 = menus compositions, 2 = menus compositions plc
    inputsSearch.typeDialog = SEARCH_PLATS_TYPE_DIALOG.PLC;
    inputsSearch.sourceObject = rowData;
    inputsSearch.searchMode = this.prefUsrSvc.getPreferenceUtilisateurIntValue(USER_PREFERENCE.GESTIONMENUS_RECHERCHE_MODE);

    return inputsSearch;
  };

  /**
   * Méthode qui initialise les actions liées au plat d'un détail
   */
  chooseAction = (menuActions: Menu, $event, rowData: DetailEffectifCommandeClientDTO) => {
    this.selectedRow = rowData;

    const modifPlat = {
      label: 'Modifier le plat',
      icon: 'fas fa-utensils',
      command: (event) => {
        this.selectedDetail = Object.assign({}, rowData);
        const inputs = this.inputsForSearch(rowData);
        this.mp2Svc.announceInputRecherchePlats(inputs);
      }
    };

    const addPlat = {
      label: 'Ajouter un plat',
      icon: 'fas fa-utensils',
      command: (event) => {
        this.selectedDetail = Object.assign({}, rowData);
        const inputs = this.inputsForSearch(rowData);
        this.mp2Svc.announceInputRecherchePlats(inputs);

      }
    };

    const removePlat = {
      label: 'Enlever le plat',
      icon: 'fas fa-trash',
      command: (event) => {
        this.selectedDetail = Object.assign({}, rowData);
        this.selectedDetail.actif = false;
        this.selectedDetail.modificationManuelle = true;
        this.isUpdated = false;
        this.changeProduitDeclinaison(null, true);
      }
    };

    const reinitPlat = {
      label: 'Réinitialiser le plat',
      icon: 'fas fa-redo',
      command: (event) => {
        this.selectedDetail = Object.assign({}, rowData);
        this.selectedDetail.actif = true;
        this.selectedDetail.modificationManuelle = false;
        this.isUpdated = false;
        this.changeProduitDeclinaison(true);
      }
    };

    // si plat existe, on peut modifier / supprimer / reinitialiser
    if (!this.utils.isNullOrEmpty(rowData) && !this.utils.isNullOrEmpty(rowData.mcPlc)) {
      if (rowData.actif) {
        this.actions = [modifPlat, removePlat, reinitPlat];
      } else {
        this.actions = [reinitPlat];
      }
    }
    // si plat n'existe pas
    else {
      this.actions = [addPlat];
    }
    menuActions.show($event);
  };

  /**
   * Méthode qui permet de formatter l'affichage d'un label lorsque l'utilisateur passe sa souris sur le libellé du plat d'un détail
   */
  getHistoLabel = (item: DetailEffectifCommandeClientDTO) => {
    if (!this.utils.isNullOrEmpty(item)) {
      const dateCreation = this.utils.convertNumberDateToDate(item.dateCreation);
      const dateDerniereModification = this.utils.convertNumberDateToDate(item.dateDerniereModification);
      return `Créé le ${this.utils.getFrenchDateHHMM(dateCreation.getTime())} par ${item.creerPar}.<br><br>Dernière modification le ${this.utils.getFrenchDateHHMM(dateDerniereModification.getTime())} par ${item.modifierPar}.`;
    }
  };

  isInputDisabled = (row: DetailEffectifCommandeClientDTO) => !this.auth2Svc.isSiteLocal(row.mcPlc.cmcrPlcDate.cmSiteId);

  canDisplayGrid = (): boolean => this.detailsEffectifCommandeClient?.length > 0;
}
