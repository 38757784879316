// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[085554cbd44a5f65caaa0faa1de22fdf30ec1b26]sandbox-10356(085554cbd4)-C23/05/2024-14:30:07-B28/05/2024-12:04:20' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[085554cbd44a5f65caaa0faa1de22fdf30ec1b26]sandbox-10356(085554cbd4)-C23/05/2024-14:30:07-B28/05/2024-12:04:20",
  branch: "085554cbd44a5f65caaa0faa1de22fdf30ec1b26",
  latestTag: "sandbox",
  revCount: "10356",
  shortHash: "085554cbd4",
  longHash: "085554cbd44a5f65caaa0faa1de22fdf30ec1b26",
  dateCommit: "23/05/2024-14:30:07",
  dateBuild: "28/05/2024-12:04:20",
  buildType: "Ansible",
  } ;
