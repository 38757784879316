import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {MenuComposition__PlcDTO} from '../../dtos/menucomposition-plc-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {Subject} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {DialogRecherchePlatsSupplier} from '../gestionmenus/menus-planning2.service';
import DetailEffectifCommandeClientDTO from "../../dtos/planification/detail-effectif-commande-client-dto";
import {TypeEffectifEnum} from "../../enums/type-effectif-enum";

export const URL_GET_REMOVE_PLAT_PLC = `dolrest/gestion-clients/point-de-livraison/plat/remove`;
export const URL_GET_REINIT_PLAT_PLC = `dolrest/gestion-clients/point-de-livraison/plat/restore`;
export const URL_POST_SAVE_PLAT_PLC = `dolrest/gestion-clients/point-de-livraison/plat/save`;

@Injectable({
  providedIn: 'root'
})
export class MenucompositionPlcService extends GenericHandler<MenuComposition__PlcDTO> {

  // ajouter / modifier  un plat à partir de l'écran de recherche
  private subjectAddProduitDeclinaisonFromSearch = new Subject<DialogRecherchePlatsSupplier>();
  addProduitDeclinaisonFromSearch$ = this.subjectAddProduitDeclinaisonFromSearch.asObservable();

  constructor(utils: UtilsService,
              auth2Svc: Auth2Service,
              router: Router,
              http: HttpClient,
              title: Title,
              private gds: GenericDatagridService) {
    super(utils, auth2Svc, router, http, title);
  }

  createEmptyDTO = (): MenuComposition__PlcDTO => undefined;

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => "";

  getEditObjectLabel = (data: ObjectDTO): string => "";

  getEntityName = (): string => "MenuComposition__Plc";

  getFields = (dto: ObjectDTO): FormFieldBaseSupplier<any>[] => [];

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => [];

  getTitle = (): string => "";

  getTotalRecordsLabel = (): string => "";

  removePlat = (idMcPlc: number) => {
    return this.http.get(URL_GET_REMOVE_PLAT_PLC, {
      params: new HttpParams().set('idMenuCompositionPlc', idMcPlc + '')
    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

  reinitPlat = (idMcPlc: number) => {
    return this.http.get(URL_GET_REINIT_PLAT_PLC, {
      params: new HttpParams().set('idMenuCompositionPlc', idMcPlc + '')
    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

  announceProduitDeclinaisonFromSearch = (searchSupplier: DialogRecherchePlatsSupplier) => {
    this.subjectAddProduitDeclinaisonFromSearch.next(searchSupplier);
  };

  createMcPlc = (response: DialogRecherchePlatsSupplier) => {
    const mcPlc = new MenuComposition__PlcDTO();
    const row: DetailEffectifCommandeClientDTO = response.sourceObject;

    mcPlc.cmcd = row.cmcd;
    mcPlc.actif = true;
    mcPlc.produitDeclinaison = response.produitsDeclinaisonsInCart[0];
    mcPlc.ordre = row.mcPlc.ordre;
    mcPlc.modificationManuelle = true;
    mcPlc.cmcrPlcDate = row.mcPlc.cmcrPlcDate;

    mcPlc.id = row.mcPlc ? row.mcPlc.id : 0;

    mcPlc.effectifPrevisionnel = row.typeEffectif === TypeEffectifEnum.PREVISIONNEL ? row.effectifPrevu : 0;
    mcPlc.effectifFabrication = row.typeEffectif === TypeEffectifEnum.FABRICATION ? row.effectifPrevu : 0;
    mcPlc.effectifFacture = row.typeEffectif === TypeEffectifEnum.FACTURATION ? row.effectifPrevu : 0;

    mcPlc.tpEffectifPrevisionnel = row.typeEffectif === TypeEffectifEnum.PREVISIONNEL ? row.tauxPrise : 0;
    mcPlc.tpEffectifFabrication = row.typeEffectif === TypeEffectifEnum.FABRICATION ? row.tauxPrise : 0;
    mcPlc.tpEffectifFacture = row.typeEffectif === TypeEffectifEnum.FACTURATION ? row.tauxPrise : 0;

    mcPlc.aEffectifPrevisionnel = row.typeEffectif === TypeEffectifEnum.PREVISIONNEL ?  row.arrondiEffectifPrevu : false;
    mcPlc.aEffectifFabrication = row.typeEffectif === TypeEffectifEnum.FABRICATION ? row.arrondiEffectifPrevu : false;
    mcPlc.aEffectifFacture = row.typeEffectif === TypeEffectifEnum.FACTURATION ? row.arrondiEffectifPrevu : false;

    return mcPlc;
  };

  /**
   * Enregistrer un menu composition plc
   * @param mcPlc
   */
  save = (mcPlc: MenuComposition__PlcDTO) => {
    return this.http.post(URL_POST_SAVE_PLAT_PLC, mcPlc)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };
}
